import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { AppConfigList } from './components/pages/appConfig';
import LoginPage from './components/pages/user/LoginPage';
import { dataProvider, authProvider } from './utils';
import { theme } from './theme';

const App = () => {
        const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });

        return (
            <Admin
                title="Infrascanner"
                theme={theme}
                loginPage={LoginPage}
                i18nProvider={i18nProvider}
                dataProvider={dataProvider}
                authProvider={authProvider}
            >
                    <Resource
                        name="AppConfig"
                        options={{ label: 'App Settings' }}
                        icon={MenuBookIcon}
                        list={AppConfigList}
                    />
            </Admin>
        );
}

export default App;