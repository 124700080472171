import { defaultTheme } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import merge from 'lodash/merge';

export const theme = createMuiTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: "#10c5f8",
        contrastText: "#fff"
      },
      secondary: {
        main: "#00a7cd",
        contrastText: "#fff"
      }
    }
  })
);