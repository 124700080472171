import * as React from 'react';
import { useState, useEffect } from 'react';
import { dataProvider } from '../../../utils/dataProvider';

import { CardContent, Card, Box, Button, Select, MenuItem, Slider, Checkbox } from "@material-ui/core";


const CheckboxField = ({ record, updateParameter }) => {
  const [currentValue, setCurrentValue] = useState(record.value);
  const [previousValue, setPreviousValue] = useState(record.value);

  useEffect(() => {
    if (previousValue == currentValue) {
      return;
    }
    record.value = currentValue;
    updateParameter(record);
    setPreviousValue(currentValue);
  }, [currentValue]);

  if (record.dataType == 'Bool') {
    return <Checkbox
      checked={currentValue === true || currentValue === 'true'}
      onChange={e => setCurrentValue(e.target.checked)}
    />
  }
  if (record.fieldType == 'Dropdown') {
    return <Select
      value={currentValue}
      onChange={e => setCurrentValue(e.target.value)}
      style={{width: '100%'}}>
      {record.appConfigValues.map(value => (
        <MenuItem key={value.value} value={value.value}>{value.name}</MenuItem>
      ))}
    </Select>
  }
  if (record.fieldType == 'Slider') {
    return (<div>
      <Slider
        style={{width: '100%'}}
        min={record.minValue}
        max={record.maxValue}
        step={record.step}
        value={parseFloat(currentValue)}
        onChange={(e, value) => setCurrentValue(value)}
        valueLabelDisplay="auto"
      />
    </div>);
  }
  return null;
};

export const AppConfigList = (props) => {
  const [appConfigs, setAppConfigs] = useState([]);
  const [changedParameters, setChangedParameters] = useState([]);

  const updateParameter = (p) => {
    const currentParameters = changedParameters.filter(c => c.key != p.key);
    setChangedParameters([...currentParameters, p]);
  }
  useEffect(() => {
    const fetchAppConfigs = async () => {
      const appConfigsResponse = await dataProvider.getList( 'appConfig', {
        pagination: {},
        sort: {},
      });
      setAppConfigs(appConfigsResponse.data)
    }
    fetchAppConfigs();
  }, [])

  const updateAppConfigs = async () => {
      window.confirm('Are you sure you want to save new default settings? The settings will be applied to all devices');
      dataProvider.update('appConfig', { id: '', data: changedParameters.map(p => ({ id: p.id, value: p.value + ''}))}).then(() =>{
        setChangedParameters([]);
        alert('The settings were saved successfully');
      });
  }

  const result = appConfigs.reduce(function (r, a) {
    const key = a.settingsSection + (a.settingsSubsection ? ': ' + a.settingsSubsection : '');
    r[key] = r[key] || [];
    a.sectionKeyName = key;
    r[key].push(a);
    return r;
  }, Object.create(null));
  return (
    <div style={{ margin: 'auto', width: '60%', minWidth: '500px' }}>
      {
        Object.values(result).map((appConfigs) => {
          return (
            <div key={appConfigs[0].sectionKeyName}>
              <div>
                <h3>{appConfigs[0].sectionKeyName}</h3>
                <Box sx={{ my: 2 }}>
                  <Card>
                    <CardContent variant="outlined">
                      {
                        appConfigs.map(appConfig => (
                          <Box sx={{ my: 2 }} key={appConfig.id}>
                            {appConfig.title}
                            <div style={{ fontSize: '11px' }}>{appConfig.description}</div>
                            <div>
                              <CheckboxField record={appConfig} updateParameter={p => updateParameter(p)} />
                            </div>
                          </Box>
                        ))
                      }
                    </CardContent>
                  </Card>
                </Box>
              </div>
            </div>
          )
        })
      }
      <Button
        color={'primary'}
        variant={'outlined'}
        style={{ float: 'right' }}
        disabled={!changedParameters.length}
        onClick={() => updateAppConfigs()}
        label="Save"
      >
        Save
      </Button>
    </div>)
}
